import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const FactorySetup = () => {
  const { state } = useLocation();
  // const { dashboard_link } = state;
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated === false) {
        navigate("/");
      }
    }, 2000);
  }, []);
  const manageCertificatesUrl = process.env.REACT_APP_MACHINE_SELFSERVICE_URL
  return (
    <div>
      {isAuthenticated == true ? (
        <div className="flex flex-col">
          <Header />
          <div className="container-fluid px-0">
            <iframe height="100%" width="100%" src={manageCertificatesUrl} />
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default FactorySetup;
